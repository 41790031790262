import * as React from "react"
import PageLayout from "../../components/pageLayout"

import {Space, Button} from "antd"

import useInventoryApi from "../../services/inventory/useInventoryApi"

const InventoryManagePage = () => {


    const {
        downloadAllInventories,
        downloadActiveInventories, 
        updateActiveStocks, 
        updateStocks
    
    }  = useInventoryApi()
    return <PageLayout>

        <Space>

            <Button onClick={downloadAllInventories}>Descargar todos los inventarios</Button>
            <Button onClick={downloadActiveInventories}>Actualizar inventarios de productos activos</Button>
            <Button onClick={updateActiveStocks}>Crear promedio de  todos inventarios </Button>
            <Button onClock={updateStocks}>Crear promedio de  los inventarios activos </Button>
        </Space>

    </PageLayout>
}

export default InventoryManagePage