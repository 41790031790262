import * as React from "react"
import inventoryApi from "./index"

const useInventoryApi = () => {

    
    const downloadAllInventories = () =>{}

    const downloadActiveInventories = () => {}

    const updateStocks = () => {}

    const updateActiveStocks = () => {}
    return {
        downloadActiveInventories,
        downloadAllInventories,
        updateStocks, 
        updateActiveStocks
    }
}

export default useInventoryApi